import React from 'react';

// Components
import ContentBlock from '../../Components/ContentBlock/ContentBlock';

// Content
import { profileContent } from '../../assets/data/content';

// Styles
import styles from './About.module.css'

function About() {
    const displayMultiLineContent = () => {
        return profileContent.map((paragraph, i) => (
            <ContentBlock 
                key={i} 
                content={paragraph.content} 
                className={paragraph.class}
            />
        ))
    }
    return (
        <div className={styles.container}>
            {displayMultiLineContent()}
        </div>
    )
}

export default About