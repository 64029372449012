import React from 'react';

// styles
import styles from './Video.module.css'

function Video() {
    return (
        <div className={styles.container}>
            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/w4OMpRpCu7M" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen></iframe>
        </div>
    )
}

export default Video