import React, { useState } from 'react';

// Components
import About from '../About/About';
import Animation from '../../Components/Animation/Animation';
import Avatar from '../../Components/Avatar/Avatar'
import Button from '../../Components/Button/Button';
import Header from '../../Components/Header/Header';
import Link from '../../Components/Link/Link';
import PortfolioProject from '../../Components/PortfolioProject/PortfolioProject'
import Social from '../../Components/Social/Social'
import Video from '../../Components/Video/Video';

// Content
import coderAnimation from '../../assets/lotties/lf30_editor_qib1jjre.json'
import * as content from '../../assets/data/content.js'
import portfolioList from '../../assets/data/portfolio-list.js'


function App() {
  const [toggleAboutPage, setToggleAboutPage] = useState(false)

  const handleToggle = () => {
    return setToggleAboutPage(!toggleAboutPage)
  }

  const displayProjects = () => {
    return portfolioList.map((project, i) => (
      <PortfolioProject
        key={i}
        image={project.images[0].url}
        content={project.name}
        projectLink={project.projectLink}
        headerLevel='h2'
        pContent={project.content}
      />
    ))
  }

  return (
    <div className="app">
      <Header headerLevel='h1' content={content.siteWelcomeMessage} title={true}/>
      <Avatar/>
      <Social/>
      <About/>
    </div>
  );
}

export default App;
