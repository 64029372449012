import React from 'react';

import styles from './Header.module.css'

function Header({headerLevel, content, title}){

    const isTitle = () => {
        return title ? styles.title : styles.h1
    }

    const determineHeaderLevel = () => {
        switch(headerLevel?.toLowerCase()){
            case 'h1':
                return <h1 className={isTitle()}>{content}</h1>
            case 'h2':
                return <h2 className={styles.h2}>{content}</h2>
            case 'h3':
                return <h3 className={styles.h3}>{content}</h3>
            case 'h4':
                return <h4 className={styles.h4}>{content}</h4>
            case 'h5':
                return <h5 className={styles.h5}>{content}</h5>
            case 'h6':
                return <h6 className={styles.h6}>{content}</h6>
            default:
                return <h1 className={styles.h1}>{content}</h1>
        }
    }

    return (
        <div className={styles.container}>
            {determineHeaderLevel()}
        </div>
    )
}

export default Header