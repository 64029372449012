import React from 'react';

import styles from './Avatar.module.css'

import avatar from '../../assets/images/jonathan_herman.jpeg'

function Avatar(){
    return (
        <div className={styles.container}>
            <img className={styles.avatar} src={avatar} alt="Jonathan Herman"/>
        </div>
    )
}

export default Avatar