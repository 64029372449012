import React from 'react';

import { socialIcons } from '../../assets/data/content.js'

import styles from './Social.module.css'

function Social() {
    return (
        <div className={styles.container}>
            {socialIcons.map((icon, i) => (
                <a 
                  href={icon.link} 
                  alt={icon.name} 
                  target="_blank" 
                  rel="noreferrer"
                  key={i}
                >
                    {icon.html} 
                </a>
            ))}
        </div>
    )
}

export default Social