const siteWelcomeMessage = `Hi, I'm Jonathan Herman.`

const portfolioWelcomeMessage = 'Check out some of my latest projects below.'

const profileContent = [
    {
      content: `I’m a full stack software engineer with a blended background in data analysis, reporting, and content marketing automation and development. I get excited about using automation to create scalable solutions to business challenges, and deeply appreciate beautiful, meaningful, and easy-to-use mobile-first experiences.`,
      class: 'paragraph1'
    },
    {
      content: `Through my past experience moving from classically trained bass-baritone, to data analyst, and B2B marketer, I’ve learned how to transform to solve new problems. As a disciplined individual contributor and a trusted collaborator, I’m comfortable communicating with technical and non-technical audiences and leading across the business. My curiosity, resilience, and creativity motivate me to get things done with an outcome that delights the customer.`,
      class: ''
    },
    {
      content: `Colleagues may describe me as intellectually curious, logical, analytical, conscientious, and a creative problem solver comfortable navigating uncertainty and parsing complexity into discrete, actionable steps.`,
      class: ''
    },
    {
      content: `I pursued learning technologies such as Python, JavaScript, HTML, CSS, and SQL, and coded two games in my spare time before joining a General Assembly bootcamp in June 2021. In my past, I’ve supported top-tier brands & Fortune 500 clients including Walmart, Newegg, Target, HP and Intel.`,
      class: 'last'
    },
  ];


const socialIcons = [
  {
    name: 'GitHub',
    html: <i className="fab fa-github"></i>,
    link: 'https://github.com/jonathanherman1'
  },
  {
    name: 'LinkedIn',
    html: <i className="fab fa-linkedin"></i>,
    link: 'https://www.linkedin.com/in/jonathanherman0/'
  }
]

const resumeLink = 'https://www.dropbox.com/s/vml9sse583t69p2/Jonathan%20Herman%20Resume%202022.pdf?dl=0'

export { siteWelcomeMessage, portfolioWelcomeMessage, profileContent, socialIcons, resumeLink }