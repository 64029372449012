import React, {useState, useEffect} from 'react';

import styles from './ContentBlock.module.css'

function ContentBlock({content, className}){
    const [firstSlice, setFirstSlice] = useState('')
    const [secondSlice, setSecondSlice] = useState('')
    const [search1, setSearch1] = useState('')

    const determineClassName = () => {
        switch(className){
            case 'paragraph1':
                return styles.paragraph1
            case 'last':
                return styles.paragraphLast
            case 'portfolioParagraph':
                return styles.portfolioParagraph
            default:
                return styles.paragraph2
        }
    }

    useEffect(() => {
        const highlightKeyPhrases = () => {
            const search = 'full stack software engineer'
            setSearch1(search)
            const index = content.indexOf(search)
            if(index !== -1){
                let slice1 = content.slice(0, index)
                let slice2 = content.slice(search.length+index, content.length)
                setFirstSlice(slice1)
                setSecondSlice(slice2)
            }
        }
        highlightKeyPhrases()
    }, [content]);

    return (
        <div className={styles.container}>
            {firstSlice ?
            <p className={determineClassName()}>
              {firstSlice}
              <span className={styles.span2}>{search1}</span>
              {secondSlice} 
            </p> :
            <p className={determineClassName()}>{content}</p>
             }
        </div>
    )
}

export default ContentBlock