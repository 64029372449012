import React from 'react';

// Components
import Header from '../Header/Header';
import ImageCard from '../ImageCard/ImageCard';
import ContentBlock from '../ContentBlock/ContentBlock';
// Styles
import styles from './PortfolioProject.module.css'

function PortfolioProject(props){
    const displayMultiLineContent = () => {
        return props.pContent.map((paragraph, i) => (
            <ContentBlock 
                key={i} 
                content={paragraph.p} 
                className={paragraph.class}
            />
        ))
    }
    return (
        <div className={styles.container}>
            <a href={props.projectLink} className={styles.linkHeader} target="_blank" rel="noreferrer">
                <Header headerLevel={props.headerLevel} content={props.content}/>
                <ImageCard image={props.image}/>
            </a>
            <Header headerLevel='h5' content='Project Background'/>
            {displayMultiLineContent()}
        </div>
    )
}

export default PortfolioProject